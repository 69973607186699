import React from 'react'
import Layout from '../../components/Layout'
import SmartLink from '../../components/SmartLink'

const BuildingSeaside = () => {

  return (
    <Layout>
      <section>
        <h2>Building Seaside</h2>
        <p>
        When Robert Davis set out to build the town of Seaside in 1981 his goal was to create an idyllic town built on a human scale, where the pace was slow and relaxing; 
        the perfect spot for a vacation. In order to achieve this, Seaside would need to grow slowly, one street at a time, within a building code that sought to address the 
        social and economic concerns of the early 1980s. Using the neighboring Florida vernacular as the starting point for his code, Davis envisioned a network of houses 
        that would fit seamlessly into the neighboring communities, while also creating its own visual autonomy.
        </p>
        <p>Unlike the later New Urbanist communities that have followed it, Seaside uses multiple architects and styles. The result is an architectural masterpiece. Some 
          of the most important architects and firms from the past 30 years, from the traditionally inspired early town architects to the current modernists, are represented 
          in Seaside. The list that follows attempts to show all of the architects who have built in Seaside and the properties they have created. Just as the town of Seaside 
          is a work in progress, this list will continue to grow as more architects join the Seaside Research Portal.
        </p>

        <h2 id="a">A</h2>

        <ul>
          <h3>Advance Planning Service</h3>
          <li><SmartLink to='/structures/s101'>Sandpiper (43 Savannah)</SmartLink></li>
          <li><SmartLink to='/structures/s275'>Snail's Pace (41 Savannah)</SmartLink></li>
          <h3>Anderson, Bob</h3>
          <li><SmartLink to='/structures/s317'>Belvedere (15 West Ruskin)</SmartLink></li>
          <h3>Anderson, Don</h3>
          <li><SmartLink to='/structures/s20'>Legare (530 Forest)</SmartLink></li>
          <h3>Atkin, Tony</h3>
          <li><SmartLink to='/structures/s234'>Pensacola Steet Beach Pavilion</SmartLink></li>  
          
          <h2 id="b">B</h2>

          <h3>Banner</h3>
          <li><SmartLink to='/structures/s178'>Ecstasea (138 West Ruskin)</SmartLink></li>
          <h3>Barnes, Bill</h3>
          <li><SmartLink to='/structures/s208'>Blue Moon (655 Forest Street)</SmartLink></li>
          <h3>Baron, Errol</h3>
          <li><SmartLink to='/structures/s302'>Seven Oaks (81 Seaside Avenue)</SmartLink></li>
          <h3>Benos, Rick</h3>
          <li><SmartLink to='/structures/s130'>Green Gables (472 Forest)</SmartLink></li>
          <h3>Berke, Deborah</h3>
          <li><SmartLink to='/structures/s345'>Aquila (22 Pensacola)</SmartLink></li>
          <li><SmartLink to='/structures/s293'>As Good As It Gets (60 East Ruskin Street)</SmartLink></li>
          <li><SmartLink to='/structures/s291'>Blue Suede Shoes (52 East Ruskin)</SmartLink></li>
          <li><SmartLink to='/structures/s125'>Colors (485 Forest)</SmartLink></li>
          <li><SmartLink to='/structures/s137'>Dream Come True (31 West Ruskin)</SmartLink></li>
          <li><SmartLink to='/structures/s228'>Giant's Roost (56 Tupelo)</SmartLink></li>
          <li><SmartLink to='/structures/s169'>Hurrah! (112 Savannah)</SmartLink></li>
          <li><SmartLink to='/structures/s187'>Jones Compound</SmartLink></li>
          <li><SmartLink to='/structures/s207'>Modica Market (109 Central Square)</SmartLink></li>
          <li><SmartLink to='/structures/s187'>Rainbow (117 Savannah)</SmartLink></li>
          <li><SmartLink to='/structures/s124'>Salad Days (102 Savannah)</SmartLink></li>
          <li><SmartLink to='/structures/s205'>Shady Lady (108 Tupelo)</SmartLink></li>
          <li><SmartLink to='/structures/s272'>Sonshine Blessings (12 Savannah)</SmartLink></li>
          <li><SmartLink to='/structures/s278'>Sward House (69 Savannah)</SmartLink></li>
          <li><SmartLink to='/structures/s4'>The Panhandle (25 Tupelo)</SmartLink></li>
          <li><SmartLink to='/structures/s150'>Whatever (44 East Ruskin Street)</SmartLink></li>
          <h3>Bitterli, Robert</h3>
          <li><SmartLink to='/structures/s100'>Persuasion (42 Savannah)</SmartLink></li>
          <h3>Blimling, Sam</h3>
          <li><SmartLink to='/structures/s194'>Beach Side Cabana (32 Natchez)</SmartLink></li>
          <h3>Boerder, Larry</h3>
          <li><SmartLink to='/structures/s379'>Watertower (177 Grayton Street)</SmartLink></li>
          <h3>Bogardus, Ralph</h3>
          <li><SmartLink to='/structures/s158'>American Dream (19 Grayton)</SmartLink></li>
          <li><SmartLink to='/structures/s138'>Another Dream (21 Grayton)</SmartLink></li>
          <h3>Boomer</h3>
          <li><SmartLink to='/structures/s111'>Trio (75 Grayton)</SmartLink></li>
          <h3>Bowman, Vic</h3>
          <li><SmartLink to='/structures/s338'>Blue Belle (62 Odessa Street)</SmartLink></li>
          <li><SmartLink to='/structures/s348'>Breakaway (42 Pensacola)</SmartLink></li>
          <li><SmartLink to='/structures/s243'>CABG Patch (74 Tupelo)</SmartLink></li>
          <li><SmartLink to='/structures/s25'>Central Park (206 Ruskin Place)</SmartLink></li>
          <li><SmartLink to='/structures/s24'>Conservatory (204 Ruskin Place)</SmartLink></li>
          <li><SmartLink to='/structures/s370'>Contentment (776 Forest Street)</SmartLink></li>
          <li><SmartLink to='/structures/s292'>Dreamcatcher (53 East Ruskin)</SmartLink></li>
          <li><SmartLink to='/structures/s372'>For Rest (786 Forest)</SmartLink></li>
          <li><SmartLink to='/structures/s200'>Josephine's Guesthouse (38 Seaside Avenue)</SmartLink></li>
          <li><SmartLink to='/structures/s23'>Maiden Chambers (202 Ruskin Place)</SmartLink></li>
          <li><SmartLink to='/structures/s323'>Seaspell (47 West Ruskin)</SmartLink></li>
          <li><SmartLink to='/structures/s352'>Seaview (63 Pensacola)</SmartLink></li>
          <li><SmartLink to='/structures/s354'>Serendipity (73 Pensacola Street)</SmartLink></li>
          <li><SmartLink to='/structures/s171'>Someday's Here (37 Seaside Avenue)</SmartLink></li>
          <li><SmartLink to='/structures/s62'>Southern Splendor (751 Forest Street)</SmartLink></li>
          <li><SmartLink to='/structures/s289'>The Laughing Place (37 East Ruskin)</SmartLink></li>
          <li><SmartLink to='/structures/s38'>Tiger Paw (35 Tupelo)</SmartLink></li>
          <li><SmartLink to='/structures/s180'>Unchartered Waters (65 Grayton)</SmartLink></li>
          <h3>Breaux, Mark</h3>
          <li><SmartLink to='/structures/s358'>Gentle Breeze (28 Butler)</SmartLink></li>
          <li><SmartLink to='/structures/s82'>Sandi Shore (551 Forest Street)</SmartLink></li>
          <h3>Buch, Ernesto</h3>
          <li><SmartLink to='/structures/s92'>Brickwalk (91 Tupelo)</SmartLink></li>
          <li><SmartLink to='/structures/s76'>Dragon's Lair (52 Savannah)</SmartLink></li>
          <li><SmartLink to='/structures/s68'>Friend's Sea (92 Tupelo St)</SmartLink></li>
          <li><SmartLink to='/structures/s69'>Modica Cottage (15 Tupelo)</SmartLink></li>
          <li><SmartLink to='/structures/s207'>Modica House</SmartLink></li>
          <li><SmartLink to='/structures/s412'>Public Works</SmartLink></li>
          <li><SmartLink to='/structures/s229'>Tupelo Street Beach Pavilion (Tupelo Street)</SmartLink></li>
          <h3>Bullock, Pam</h3>
          <li><SmartLink to='/structures/s343'>Cloud Nine (15 Pensacola Street)</SmartLink></li>
          <li><SmartLink to='/structures/s336'>Crisobel (52 Odessa Street)</SmartLink></li>
          <li><SmartLink to='/structures/s373'>Freckles (794 Forest)</SmartLink></li>
          <li><SmartLink to='/structures/s175'>Good Timin' (624 Forest Street)</SmartLink></li>
          <li><SmartLink to='/structures/s55'>It's a Wonderful Life (608 Forest)</SmartLink></li>
          <li><SmartLink to='/structures/s33'>Just for Fun (743 Forest Street)</SmartLink></li>
          <li><SmartLink to='/structures/s305'>Kokomo (674 Forest)</SmartLink></li>
          <li><SmartLink to='/structures/s63'>Mad Jack's (775 Forest Street)</SmartLink></li>
          <li><SmartLink to='/structures/s351'>Moonshine (62 Pensacola)</SmartLink></li>
          <li><SmartLink to='/structures/s153'>My Blue Heaven (142 East Ruskin)</SmartLink></li>
          <li><SmartLink to='/structures/s134'>The Sunny Side (641 Forest Street)</SmartLink></li>
          <li><SmartLink to='/structures/s191'>Tickled Pink (665 Forest)</SmartLink></li>
          <h3>Burns, Gordon</h3>
          <li><SmartLink to='/structures/s330'>Seastar (12 Odessa)</SmartLink></li>
          <h3>Bush, Earl</h3>
          <li><SmartLink to='/structures/s329'>Maison D'Or (53 Natchez)</SmartLink></li>

          <h2 id="c">C</h2>

          <h3>Candler, Walker</h3>
          <li>Our Love (814 Forest)</li>
          <li><SmartLink to='/structures/s182'>Sea Seas (2026 County Road 30A)</SmartLink></li>
          <h3>Casas, Braulio</h3>
          <li><SmartLink to='/structures/s96'>Blakey Residence (384 Forest Street)</SmartLink></li>
          <li><SmartLink to='/structures/s302'>Bowers Residence</SmartLink></li>
          <li><SmartLink to='/structures/s95'>Breedlove Residence</SmartLink></li>
          <li><SmartLink to='/structures/s90'>Bud and Alley's Pizza Bar (2236 E County Highway 30-A)</SmartLink></li>
          <li><SmartLink to='/structures/s91'>Bud and Alley's Taco Bar</SmartLink></li>
          <li><SmartLink to='/structures/s413'>Camp Seaside Kiosk</SmartLink></li>
          <li><SmartLink to='/structures/s188'>Chatham House (71 East Ruskin Street)</SmartLink></li>
          <li><SmartLink to='/structures/s133'>Fiorenza Residence</SmartLink></li>
          <h3>Casasco, Victoria</h3>
          <li><SmartLink to='/structures/s92'>Brickwalk (91 Tupelo)</SmartLink></li>
          <li><SmartLink to='/structures/s290'>Roger's Lighthouse (45 East Ruskin Street)</SmartLink></li>
          <h3>CDA</h3>
          <li><SmartLink to='/structures/s309'>Rose & Deniz (120 Quincy Circle)</SmartLink></li>
          <h3>Chatham, Walter</h3>
          <li><SmartLink to='/structures/s27'>Albert F's (209 Ruskin Place)</SmartLink></li>
          <li><SmartLink to='/structures/s188'>Chatham House (71 East Ruskin Street)</SmartLink></li>
          <li><SmartLink to='/structures/s109'>Desire (207 Ruskin Place)</SmartLink></li>
          <li><SmartLink to='/structures/s26'>Forsythe (208 Ruskin Place)</SmartLink></li>
          <li><SmartLink to='/structures/s9'>Persimmon (143 Tupelo)</SmartLink></li>
          <li><SmartLink to='/structures/s395'>Town Hall Proposal (Smolian Circle)</SmartLink></li>
          <h3>Chian, Monica</h3>
          <li><SmartLink to='/structures/s311'>R & R (124 Quincy Circle)</SmartLink></li>
          <li><SmartLink to='/structures/s111'>Trio (75 Grayton)</SmartLink></li>
          <li><SmartLink to='/structures/s64'>Windward (130 Pensacola Street)</SmartLink></li>
          <h3>Christ, Tom</h3>
          <li><SmartLink to='/structures/s79'>A Gift From The Sea (126 Seaside Avenue)</SmartLink></li>
          <li><SmartLink to='/structures/s53'>As You Like It (536 Forest Street)</SmartLink></li>
          <li><SmartLink to='/structures/s50'>Auberge (462 Forest)</SmartLink></li>
          <li><SmartLink to='/structures/s6'>By The Sea (79 Tupelo St)</SmartLink></li>
          <li><SmartLink to='/structures/s332'>Caribbean (23 Odessa)</SmartLink></li>
          <li><SmartLink to='/structures/s350'>Crystal Blue Persuasion (53 Pensacola)</SmartLink></li>
          <li><SmartLink to='/structures/s161'>Curphey Cottage (54 Natchez)</SmartLink></li>
          <li><SmartLink to='/structures/s135'>Do Little (133 West Ruskin)</SmartLink></li>
          <li><SmartLink to='/structures/s129'>Fish Lips (177 East Ruskin)</SmartLink></li>
          <li><SmartLink to='/structures/s277'>Flamingo (61 Savannah)</SmartLink></li>
          <li><SmartLink to='/structures/s44'>Good Abode (118 Tupelo)</SmartLink></li>
          <li><SmartLink to='/structures/s286'>Gulfview (28 East Ruskin)</SmartLink></li>
          <li><SmartLink to='/structures/s347'>Happily Ever After (33 Pensacola)</SmartLink></li>
          <li><SmartLink to='/structures/s152'>Her Wildlife Refuge (67 Seaside Avenue)</SmartLink></li>
          <li><SmartLink to='/structures/s281'>James (418 West Grove Ave.)</SmartLink></li>
          <li><SmartLink to='/structures/s192'>Lemon Tree Resort (181 Smolian Circle)</SmartLink></li>
          <li><SmartLink to='/structures/s45'>Limoncello (131 Tupelo)</SmartLink></li>
          <li><SmartLink to='/structures/s107'>Made in the Shade (561 Forest)</SmartLink></li>
          <li><SmartLink to='/structures/s51'>Meant to Be (498 Forest)</SmartLink></li>
          <li><SmartLink to='/structures/s280'>Mellow Yellow (94 Savannah)</SmartLink></li>
          <li><SmartLink to='/structures/s320'>Moore Fun (30 West Ruskin)</SmartLink></li>
          <li><SmartLink to='/structures/s58'>Morning in May (151 Smolian Circle)</SmartLink></li>
          <li><SmartLink to='/structures/s159'>Nana's Sunshine (44 Natchez)</SmartLink></li>
          <li><SmartLink to='/structures/s86'>Our Place by the Sea (652 Forest St)</SmartLink></li>
          <li><SmartLink to='/structures/s303'>Park Place (571 Forest)</SmartLink></li>
          <li><SmartLink to='/structures/s294'>Pitter Patter (61 East Ruskin)</SmartLink></li>
          <li><SmartLink to='/structures/s60'>Saratoga (725 Forest)</SmartLink></li>
          <li><SmartLink to='/structures/s99'>Savannah Sea Cabin (2408 County Road 30A)</SmartLink></li>
          <h3>Savannah Street Beach Pavilion</h3>
          <li><SmartLink to='/structures/s279'>Shell Seeker (80 Savannah)</SmartLink></li>
          <li><SmartLink to='/structures/s149'>Son Worshippers (130 Savannah)</SmartLink></li>
          <li><SmartLink to='/structures/s121'>Southern Exposure (2368 County Road 30-A)</SmartLink></li>
          <li><SmartLink to='/structures/s353'>Sundaze (72 Pensacola Street)</SmartLink></li>
          <li><SmartLink to='/structures/s322'>Take Five (39 West Ruskin)</SmartLink></li>
          <li><SmartLink to='/structures/s122'>Time Out (20 Savannah)</SmartLink></li>
          <li><SmartLink to='/structures/s43'>Tucker's Tupelo Tower (93 Tupelo)</SmartLink></li>
          <li><SmartLink to='/structures/s376'>Tuttaposto (876 Forest)</SmartLink></li>
          <li><SmartLink to='/structures/s37'>Upper East Side (14 Tupelo)</SmartLink></li>
          <li><SmartLink to='/structures/s8'>Villa Whimsey (119 Tupelo)</SmartLink></li>
          <li><SmartLink to='/structures/s202'>When a Man Loves a Woman (26 Natchez Street)</SmartLink></li>
          <h3>Coker, Coleman</h3>
          <li><SmartLink to='/structures/s360'>Latitude (54 Butler)</SmartLink></li>
          <h3>Coleman, David</h3>
          <li><SmartLink to='/structures/s210'>Coleman Beach Pavilion</SmartLink></li>
          <h3>Cooper, Don</h3>
          <li><SmartLink to='/structures/s136'>Art & Soul (303 Ruskin Place)</SmartLink></li>
          <li><SmartLink to='/structures/s219'>Big (143 Seaside Avenue)</SmartLink></li>
          <li><SmartLink to='/structures/s377'>Bonny Dune (882 Forest Street)</SmartLink></li>
          <li><SmartLink to='/structures/s156'>Chatelet (307 Ruskin Place)</SmartLink></li>
          <li><SmartLink to='/structures/s325'>Dreamweaver (127 Grayton Street)</SmartLink></li>
          <li><SmartLink to='/structures/s201'>Elegance (161 Smolian Circle)</SmartLink></li>
          <li><SmartLink to='/structures/s196'>Fantasia (85 Tupelo)</SmartLink></li>
          <li><SmartLink to='/structures/s387'>Felicity (852 Forest)</SmartLink></li>
          <li><SmartLink to='/structures/s300'>For Keeps (112 Seaside Avenue)</SmartLink></li>
          <li><SmartLink to='/structures/s223'>Frumious Bandersnatch (2002 County Road 30-A)</SmartLink></li>
          <li><SmartLink to='/structures/s340'>Grand Central (72 Odessa Street)</SmartLink></li>
          <li><SmartLink to='/structures/s334'>Heavenly Days (42 Odessa Street)</SmartLink></li>
          <li><SmartLink to='/structures/s327'>Propinquity (16 Natchez Street)</SmartLink></li>
          <li><SmartLink to='/structures/s378'>Sandia (155 Grayton Street)</SmartLink></li>
          <li><SmartLink to='/structures/s409'>Savannah Rose (81 Savannah)</SmartLink></li>
          <li><SmartLink to='/structures/s324'>Song of the Sea (53 West Ruskin)</SmartLink></li>
          <li><SmartLink to='/structures/s80'>String of Pearls (127 Seaside Avenue)</SmartLink></li>
          <li><SmartLink to='/structures/s160'>The Truman House (31 Natchez Street)</SmartLink></li>
          <li><SmartLink to='/structures/s321'>Tide's Inn (38 West Ruskin)</SmartLink></li>
          <li><SmartLink to='/structures/s237'>Toye (2454 County Road 30-A)</SmartLink></li>
          <h3>Crawford / McWilliams / Hatcher</h3>
          <li><SmartLink to='/structures/s70'>Sandcastle (99 Tupelo)</SmartLink></li>

          <h2 id="D">D</h2>

          <h3>Dagenhart, Richard</h3>
          <li><SmartLink to='/structures/s7'>Ivy (102 Tupelo)</SmartLink></li>
          <li><SmartLink to='/structures/s133'>Script (545 Forest Street)</SmartLink></li>
          <h3>Davis, Robert</h3>
          <li><SmartLink to='/structures/s2'>Bella Vista (24 Tupelo)</SmartLink></li>
          <li><SmartLink to='/structures/s282'>Davis House (115 Seaside Avenue)</SmartLink></li>
          <li><SmartLink to='/structures/s3'>Mabel Haardt's Cottage (34 Tupelo)</SmartLink></li>
          <li><SmartLink to='/structures/s205'>Shady Lady (108 Tupelo)</SmartLink></li>
          <h3>DAG Architects Inc.</h3>
          <li><SmartLink to='/structures/s301'>A Summer Place (428 West Grove Ave.)</SmartLink></li>
          <li><SmartLink to='/structures/s207'>Modica Market (109 Central Square)</SmartLink></li>
          <h3>Dowling, James</h3>
          <li><SmartLink to='/structures/s173'>Skid Roe (142 Seaside Avenue)</SmartLink></li>
          <h3>Duch, Brett</h3>
          <li><SmartLink to='/structures/s81'>Beach Bunny (159 East Ruskin)</SmartLink></li>
          <h3>Dunseth, Charles</h3>
          <li><SmartLink to='/structures/s306'>Smitten (684 Forest)</SmartLink></li>

          <h2 id="E">E</h2>

          <h3>Evans, Dennis</h3>
          <li><SmartLink to='/structures/s318'>Suntime (20 West Ruskin)</SmartLink></li>

          <h2 id="F">F</h2>

          <h3>Ferri, Roger</h3>
          <li><SmartLink to='/structures/s218'>Odessa Street Beach Pavilion</SmartLink></li>
          <h3>Furr, Susan</h3>
          <li><SmartLink to='/structures/s333'>Sealink (33 Odessa)</SmartLink></li>

          <h2 id="G">G</h2>

          <h3>Garrison, Aubrey</h3>
          <li><SmartLink to='/structures/s78'>The Beach House (103 Savannah)</SmartLink></li>
          <h3>Gibbs, Richard</h3>
          <li><SmartLink to='/structures/s386'>Banana Republic (842 Forest)</SmartLink></li>
          <li><SmartLink to='/structures/s152'>Her Wildlife Refuge (67 Seaside Avenue)</SmartLink></li>
          <li><SmartLink to='/structures/s213'>Idyll by the Sea (2040 County Road 30-A)</SmartLink></li>
          <li><SmartLink to='/structures/s401'>Kaya (712 Forest)</SmartLink></li>
          <li><SmartLink to='/s108'>Life is Good (664 Forest)</SmartLink></li>
          <li><SmartLink to='/structures/s106'>Little Lodge (552 Forest)</SmartLink></li>
          <li><SmartLink to='/structures/s172'>Nunnie's Money (52 Seaside Avenue)</SmartLink></li>
          <li><SmartLink to='/structures/s270'>Proteus (2410 County Road 30-A)</SmartLink></li>
          <li><SmartLink to='/structures/s366'>Sea Biscuit (750 Forest St)</SmartLink></li>
          <li><SmartLink to='/structures/s212'>Sea Forever (1992 County Road 30-A)</SmartLink></li>
          <li><SmartLink to='/structures/s393/'>Seaside Neighborhood School (10 Smolian Circle)</SmartLink></li>
          <li><SmartLink to='/structures/s385'>The House With No Name (834 Forest)</SmartLink></li>
          <h3>Gibbs / Chian / Boomer</h3>
          <li><SmartLink to='/structures/s111'>Trio (75 Grayton)</SmartLink></li>
          <h3>Gorlin, Alexander</h3>
          <li><SmartLink to='/structures/s118'>About Time Again (2030 County Road 30-A)</SmartLink></li>
          <li><SmartLink to='/structures/s15'>Beach Music (203 Ruskin Place)</SmartLink></li>
          <li><SmartLink to='/structures/s14'>Eclipse (301 Ruskin Place)</SmartLink></li>
          <li><SmartLink to='/structures/s48'>La Maison deVolette (201 Ruskin Place)</SmartLink></li>
          <li><SmartLink to='/structures/s13'>Parador (205 Ruskin Place)</SmartLink></li>
          <li><SmartLink to='/structures/s16'>Stairway to Heaven (302 Ruskin Place)</SmartLink></li>
          <h3>Grenader, Nonya</h3>
          <li><SmartLink to='/structures/s87'>Overboard! (88 Odessa)</SmartLink></li>
          <h3>Gresham, Warren</h3>
          <li><SmartLink to='/structures/s41'>Sundance (75 Tupelo)</SmartLink></li>

          <h2 id="H">H</h2>

          <h3>Haizlip, Reb</h3>
          <li><SmartLink to='/structures/s357'>Summer Solstice (12 Butler)</SmartLink></li>
          <h3>Hartsell</h3>
          <li><SmartLink to='/structures/s178'>Ecstasea (138 West Ruskin)</SmartLink></li>
          <h3>Hatch, Holly</h3>
          <li><SmartLink to='/structures/s165'>Sandity (2000 County Road 30-A)</SmartLink></li>
          <h3>Hicks</h3>
          <li><SmartLink to='/structures/s232'>After All (20 Robert's Way)</SmartLink></li>
          <h3>Hiett, Louis</h3>
          <li><SmartLink to='/structures/s132'>Goodnight Moon (507 Forest)</SmartLink></li>
          <li><SmartLink to='/structures/s346'>Sisters Three (32 Pensacola)</SmartLink></li>
          <li><SmartLink to='/structures/s299'>Vista del Mar (100 Seaside Avenue)</SmartLink></li>
          <li><SmartLink to='/structures/s19'>Wash Ashore (520 Forest)</SmartLink></li>
          <li><SmartLink to='/structures/s287'>ZZZ's By the Sea (29 East Ruskin)</SmartLink></li>
          <h3> Highland Partners Architects</h3>
          <li><SmartLink to='/structures/s73'>Fernleigh (30 Savannah)</SmartLink></li>
          <h3>Holl, Steven</h3>
          <li><SmartLink to='/structures/s233'>ARCH-SEASIDE:162 (121 Central Square)</SmartLink></li>
          <h3>Horn, Peter</h3>
          <li><SmartLink to='/structures/s71'>Jasper (153 Tupelo)</SmartLink></li>
          <li><SmartLink to='/structures/s154'>Mom's Off Duty (517 Forest)</SmartLink></li>
          <h3>Hudson, Suellen</h3>
          <li><SmartLink to='/structures/s181'>Row Jimmy (52 Pensacola)</SmartLink></li>
          <li><SmartLink to='/structures/s54'>Sunflower (546 Forest Street)</SmartLink></li>
          <li><SmartLink to='/structures/s337'>Tersilio (53 Odessa Street)</SmartLink></li>
          <li><SmartLink to='/structures/s284'>Waterside (11 East Ruskin Street)</SmartLink></li>

          <h2 id="I">I</h2>

          <h3>Intramuros Design Inc.</h3>
          <li><SmartLink to='/structures/s86'>Our Love (814 Forest)</SmartLink></li>
          <h3>Israel, George</h3>
          <li><SmartLink to='/structures/s203'>Carpe Diem (771 Forest Street)</SmartLink></li>
          <h3>Issacs, Ken</h3>
          <li><SmartLink to='/structures/s157'>Sabbatical/Dreamboat (14 West Ruskin)</SmartLink></li>

          <h2 id="J">J</h2>

          <h3>Jersey Devil</h3>
          <li><SmartLink to='/structures/s356'>Natchez Street Beach Pavilion</SmartLink></li>
          <h3>Johansson, Sture</h3>
          <li><SmartLink to='/structures/s155'>Apple Pie (600 Forest Street)</SmartLink></li>
          <li><SmartLink to='/structures/s342'>Play Therapy (14 Pensacola Street)</SmartLink></li>
          <li><SmartLink to='/structures/s368'>With Much Love (764 Forest Street)</SmartLink></li>
          <li><SmartLink to='/structures/s367'>Zea Creature (758 Forest Street)</SmartLink></li>
          <h3>Justiss, Gary</h3>
          <li><SmartLink to='/structures/s328'>Narnia (43 Natchez)</SmartLink></li>

          <h2 id="K">K</h2>

          <h3>Kennedy, Ann Keith</h3>
          <li><SmartLink to='/structures/s57'>Lucy's Lodge (660 Forest Street)</SmartLink></li>
          <h3>Kotas, Jeremy</h3>
          <li><SmartLink to='/structures/s361'>Pleasure Principle (64 Butler)</SmartLink></li>
          <h3>Krier, Leon</h3>
          <li><SmartLink to='/structures/s244'>Krier House (84 Tupelo Street)</SmartLink></li>

          <h2 id="L">L</h2>

          <h3>Lamar, Robert</h3>
          <li><SmartLink to='/structures/s276'>Bit-O-Heaven (49 Savannah)</SmartLink></li>
          <li><SmartLink to='/structures/s168'>Double Your Cottage (51 Savannah)</SmartLink></li>
          <li><SmartLink to='/structures/s400'>Sun Kissed (12 East Ruskin)</SmartLink></li>
          <h3>Laurent, Benoit</h3>
          <li><SmartLink to='/structures/s104'>Blue Note (529 Forest)</SmartLink></li>
          <li><SmartLink to='/structures/s75'>Carey (50 Savannah)</SmartLink></li>
          <li><SmartLink to='/structures/s220'>Hakuna Matata (111 Grayton Street)</SmartLink></li>
          <li><SmartLink to='/structures/s85'>In The Pines (635 Forest Street)</SmartLink></li>
          <li><SmartLink to='/structures/s39'>Sugar (47 Tupelo)</SmartLink></li>
          <li><SmartLink to='/structures/s339'>Veranda (63 Odessa Street)</SmartLink></li>
          <h3>Lawson, Al</h3>
          <li><SmartLink to='/structures/s61'>Mirador (735 Forest)</SmartLink></li>
          <h3>Lovell</h3>
          <li><SmartLink to='/structures/s232'>After All (20 Robert's Way)</SmartLink></li>

          <h2 id="M">M</h2>

          <h3>Mabire, Michael</h3>
          <h3>Mabire and Porter Residential Design</h3>
          <li><SmartLink to='/structures/s56'>Kano (632 Forest Street)</SmartLink></li>
          <h3>Machado & Silvetti Associates</h3>
          <li><SmartLink to='/structures/s225'>Central Square Suites (25 Central Square)</SmartLink></li>
          <h3>Maddox, Dave</h3>
          <li><SmartLink to='/structures/s130'>Green Gables (472 Forest)</SmartLink></li>
          <h3>Martz, Randolph</h3>
          <li><SmartLink to='/structures/s42'>Eversong (80 Tupelo)</SmartLink></li>
          <li><SmartLink to='/structures/s363'>Independence Day (726 Forest)</SmartLink></li>
          <li><SmartLink to='/structures/s316'>Magnetic Attraction (49 Smolian Circle)</SmartLink></li>
          <h3>Massengale, John</h3>
          <li><SmartLink to='/structures/s269'>Savannah Sands (2376 County Road 30-A)</SmartLink></li>
          <h3>McCormick & Atelier</h3>
          <li><SmartLink to='/structures/s77'>A Day to Remember (70 Savannah)</SmartLink></li>
          <li><SmartLink to='/structures/s310'>Jim & I (121 Quincy Circle)</SmartLink></li>
          <h3>McCormick</h3>
          <li><SmartLink to='/structures/s177'>Plum Lazy/4H Club (109 West Ruskin)</SmartLink></li>
          <h3>McDonough, Michael</h3>
          <li><SmartLink to='/structures/s206'>West Ruskin Street Beach Pavilion</SmartLink></li>
          <h3>McWhorter, Carey</h3>
          <li><SmartLink to='/structures/s359'>Absolutely Annie (36 Butler)</SmartLink></li>
          <li><SmartLink to='/structures/s365'>Angel's Rest (744 Forest Street)</SmartLink></li>
          <li><SmartLink to='/structures/s381'>Take It Easy (130 Odessa Street)</SmartLink></li>
          <li><SmartLink to='/structures/s123'>The Sandbox II (60 Savannah)</SmartLink></li>
          <li><SmartLink to='/structures/s176'>Whole House (122 Quincy Circle)</SmartLink></li>
          <h3>McWilliams</h3>
          <li><SmartLink to='/structures/s70'>Sandcastle (99 Tupelo)</SmartLink></li>
          <h3>Mehall, Bob</h3>
          <li><SmartLink to='/structures/s30'>Grouper (308 Ruskin Place)</SmartLink></li>
          <li><SmartLink to='/structures/s29'>JoJo (306 Ruskin Place)</SmartLink></li>
          <li><SmartLink to='/structures/s28'>Pizzazz (304 Ruskin Place)</SmartLink></li>
          <li><SmartLink to='/structures/s31'>The Giving Tree (310 Ruskin Place)</SmartLink></li>
          <h3>Mercer, Mark</h3>
          <li><SmartLink to='/structures/s344'>Seaquel (21 Pensacola)</SmartLink></li>
          <h3>Merrill, Scott</h3>
          <li><SmartLink to='/structures/s179'>Charleston (31 Grayton)</SmartLink></li>
          <li><SmartLink to='/structures/s198'>Creole (144 Savannah)</SmartLink></li>
          <li><SmartLink to='/structures/s117'>Dune House (2423 County Road 30-A)</SmartLink></li>
          <li><SmartLink to='/structures/s382'>Enon (107 Smolian Circle)</SmartLink></li>
          <li><SmartLink to='/structures/s319'>Greenpeace (21 West Ruskin)</SmartLink></li>
          <li><SmartLink to='/structures/s151'>Harvest Home (66 Seaside Avenue)</SmartLink></li>
          <li><SmartLink to='/structures/s392'>Honeymoon Cottages (Route 30-A and East Ruskin Street)</SmartLink></li>
          <li><SmartLink to='/structures/s145'>Intermezzo by the Sea (2038 County Road 30A)</SmartLink></li>
          <li><SmartLink to='/structures/s193'>Midsummer Night's Dream (123 Grayton)</SmartLink></li>
          <li><SmartLink to='/structures/s199'>Opus (20 Seaside Avenue)</SmartLink></li>
          <li><SmartLink to='/structures/s144'>Panasea (2032 County Road 30-A)</SmartLink></li>
          <li><SmartLink to='/structures/s304'>Perceptions (599 Forest)</SmartLink></li>
          <li><SmartLink to='/structures/s165'>Sandity (2000 County Road 30-A)</SmartLink></li>
          <li><SmartLink to='/structures/s407'>Seaside Chapel (Ruskin Square)</SmartLink></li>
          <li><SmartLink to='/structures/s227'>Seaside Motor Court (Quincy Circle)</SmartLink></li>
          <li><SmartLink to='/structures/s84'>Sunshine (629 Forest)</SmartLink></li>
          <li><SmartLink to='/structures/s164'>Sweet Spot (2418 County Road 30-A)</SmartLink></li>
          <h3>Mockbee, Sam</h3>
          <li><SmartLink to='/structures/s360'>Latitude (54 Butler)</SmartLink></li>
          <h3>Mulberry, Patrick</h3>
          <li><SmartLink to='/structures/s364'>Kays HaSEAienda (736 Forest Street)</SmartLink></li>
          <li><SmartLink to='/structures/s112'>Lonesome Dove (25 Natchez)</SmartLink></li>
          <li><SmartLink to='/structures/s113'>Wean Back and Wewax (32 Odessa)</SmartLink></li>
          <li><SmartLink to='/structures/s335'>Wheeler (43 Odessa Street)</SmartLink></li>
          <h3>Murray, Bill</h3>
          <li><SmartLink to='/structures/s52'>Bobby D's Gold Rec'd (506 Forest)</SmartLink></li>
          <h3>Myers, E.A.</h3>
          <li><SmartLink to='/structures/s174'>It's Always Five O'Clock (152 East Ruskin)</SmartLink></li>

          <h2 id="N">N</h2>

          <h3>Norris, Robert</h3>
          <li><SmartLink to='/structures/s326'>Key West (15 Natchez)</SmartLink></li>
          <h3>Nunn, Ty</h3>
          <li><SmartLink to='/structures/s126'>Early Morning Hours (123 Quincy Circle)</SmartLink></li>

          <h2 id="O">O</h2>

          <h3>Orr, Robert</h3>
          <li><SmartLink to='/structures/s241'>Acadia (46 Robert's Way)</SmartLink></li>
          <li><SmartLink to='/structures/s388'>Blessings (64 Robert's Way)</SmartLink></li>
          <li><SmartLink to='/structures/s390'>C-Side Cottage (78 Robert's Way)</SmartLink></li>
          <li><SmartLink to='/structures/s143'>Dreamsicle (48 Tupelo)</SmartLink></li>
          <li><SmartLink to='/structures/s389'>English Cottage (70 Robert's Way)</SmartLink></li>
          <li><SmartLink to='/structures/s209'>Fish Tales (39 Rosewalk)</SmartLink></li>
          <li><SmartLink to='/structures/s240'>Limehouse (40 Robert's Way)</SmartLink></li>
          <li><SmartLink to='/structures/s162'>Mis B'Haven (19 Rosewalk)</SmartLink></li>
          <li><SmartLink to='/structures/s356'>Natchez House (147 Grayton)</SmartLink></li>
          <li><SmartLink to='/structures/s235'>Rosewalk</SmartLink></li>
          <li><SmartLink to='/structures/s139'>Sausalito (29 Rosewalk)</SmartLink></li>
          <li><SmartLink to='/structures/s242'>Spinnaker (54 Robert's Way)</SmartLink></li>
          <li><SmartLink to='/structures/s238'>Treetops (10 Robert's Way)</SmartLink></li>

          <h2 id="P">P</h2>

          <h3>Parolek, Daniel</h3>
          <li><SmartLink to='/structures/s394'>Town Square</SmartLink></li>
          <h3>Patton, Tom</h3>
          <li><SmartLink to='/structures/s49'>Watersmeet (160 East Ruskin)</SmartLink></li>
          <h3>Pelli, Rafael</h3>
          <li><SmartLink to='/structures/s87'>Overboard! (88 Odessa)</SmartLink></li>
          <li><SmartLink to='/structures/s296'>Topisaw (78 East Ruskin)</SmartLink></li>
          <h3>Prescott, Jeff</h3>
          <li><SmartLink to='/structures/s74'>3+3=6 (40 Savannah)</SmartLink></li>
          <li><SmartLink to='/structures/s369'>Brigadoon (770 Forest Street)</SmartLink></li>

          <h2 id="R">R</h2>

          <h3>Rossi, Aldo</h3>
          <li><SmartLink to='/structures/s246'>Variance (1976 County Rd 30-A)</SmartLink></li>

          <h2 id="S">S</h2>

          <h3>Sabella, William</h3>
          <li><SmartLink to='/structures/s295'>Sabella (70 East Ruskin)</SmartLink></li>
          <h3>Sanford, Fay</h3>
          <li><SmartLink to='/structures/s331'>Starry Night (13 Odessa)</SmartLink></li>
          <h3>Searcy, Dennis</h3>
          <li><SmartLink to='/structures/s371'>Ohh La La (785 Forest)</SmartLink></li>
          <h3>Shafer, Skip</h3>
          <li><SmartLink to='/structures/s361'>Pleasure Principle (64 Butler)</SmartLink></li>
          <h3>Shafer Banner Hartsell & Associates</h3>
          <li><SmartLink to='/structures/s178'>Ecstasea (138 West Ruskin)</SmartLink></li>
          <h3>Shipp, Charles</h3>
          <li><SmartLink to='/structures/s308'>Cherished by the Sea (119 Quincy Circle)</SmartLink></li>
          <h3>Smith, Derrick</h3>
          <li><SmartLink to='/structures/s285'>4th of July (20 East Ruskin)</SmartLink></li>
          <li><SmartLink to='/structures/s274'>Abracadabra (29 Savannah)</SmartLink></li>
          <li><SmartLink to='/structures/s239'>Bridgeport (30 Robert's Way)</SmartLink></li>
          <li><SmartLink to='/structures/s128'>Lady on the Beach (170 East Ruskin Street)</SmartLink></li>
          <li><SmartLink to='/structures/s94'>Marisol (150 Tupelo)</SmartLink></li>
          <li><SmartLink to='/structures/s105'>Merci Beaucoup (535 Forest Street)</SmartLink></li>
          <li><SmartLink to='/structures/s271'>Monday's Child (11 Savannah)</SmartLink></li>
          <li><SmartLink to='/structures/s288'>N.O. Angel (36 East Ruskin)</SmartLink></li>
          <li><SmartLink to='/structures/s40'>Summertime (57 Tupelo)</SmartLink></li>
          <h3>Smith, Fleming, III</h3>
          <li><SmartLink to='/structures/s341'>Somewhere in Time (73 Odessa)</SmartLink></li>
          <h3>Spitzmiller and Norris</h3>
          <li><SmartLink to='/structures/s326'>Key West (15 Natchez)</SmartLink></li>
          <h3>Stallings, Ryce</h3>
          <li><SmartLink to='/structures/s131'>Sawyer House (486 Forest)</SmartLink></li>
          <h3>Stanziale, Robert</h3>
          <li><SmartLink to='/structures/s362'>Three Flights Up (720 Forest)</SmartLink></li>
          <h3>Stern, Robert A.M.</h3>
          <li><SmartLink to='/structures/s72'>Nesbitt House (2120 County Road 30-A)</SmartLink></li>
          <h3>Stuart Cohen and Anders Nereim Architects</h3>
          <li><SmartLink to='/structures/s184'>East Ruskin Street Beach Pavilion (East Ruskin Street)</SmartLink></li>

          <h2 id="T">T</h2>

          <h3>Tamme, Konrad</h3>
          <li><SmartLink to='/structures/s189'>Paper Moon (176 East Ruskin)</SmartLink></li>
          <h3>Taylor, Melanie</h3>
          <li><SmartLink to='/structures/s241'>Acadia (46 Robert's Way)</SmartLink></li>
          <li><SmartLink to='/structures/s5'>Beach Baby (46 Tupelo)</SmartLink></li>
          <li><SmartLink to='/structures/s388'>Blessings (64 Robert's Way)</SmartLink></li>
          <li><SmartLink to='/structures/s390'>C-Side Cottage (78 Robert's Way)</SmartLink></li>
          <li><SmartLink to='/structures/s143'>Dreamsicle (48 Tupelo)</SmartLink></li>
          <li><SmartLink to='/structures/s389'>English Cottage (70 Robert's Way)</SmartLink></li>
          <li><SmartLink to='/structures/s209'>Fish Tales (39 Rosewalk)</SmartLink></li>
          <li><SmartLink to='/structures/s240'>Limehouse (40 Robert's Way)</SmartLink></li>
          <li><SmartLink to='/structures/s162'>Mis B'Haven (19 Rosewalk)</SmartLink></li>
          <li><SmartLink to='/structures/s139'>Sausalito (29 Rosewalk)</SmartLink></li>
          <li><SmartLink to='/structures/s242'>Spinnaker (54 Robert's Way)</SmartLink></li>
          <li><SmartLink to='/structures/s238'>Treetops (10 Robert's Way)</SmartLink></li>
          <h3>Terrell, Tim</h3>
          <li><SmartLink to='/structures/s21'>Knot Forrent (616 Forest)</SmartLink></li>
          <h3>Trelles Cabarrocas Architects</h3>
          <li><SmartLink to='/structures/s17'>Dragonette Townhouse (210 Ruskin Place)</SmartLink></li>
          <h3>Troxel, Cheryl</h3>
          <li><SmartLink to='/structures/s93'>Lovey's Surprise (128 Tupelo)</SmartLink></li>
          <li><SmartLink to='/structures/s83'>Trade Winds (568 Forest)</SmartLink></li>
          <li><SmartLink to='/structures/s126'>Early Morning Hours (123 Quincy Circle)</SmartLink></li>

          <h2 id="V">V</h2>

          <h3>Victoria, Teofilo</h3>
          <li><SmartLink to='/structures/s414'>Market Pavilion</SmartLink></li>

          <h2 id="W">W</h2>

          <h3>Warren, Charles</h3>
          <li><SmartLink to='/structures/s380'>Dahlgren House (108 Odessa)</SmartLink></li>
          <li><SmartLink to='/structures/s59'>Home Alone (309 Ruskin Place)</SmartLink></li>
          <li><SmartLink to='/structures/s423'>Leake House (Unbuilt)</SmartLink></li>
          <h3>Natchez Park</h3>
          <li><SmartLink to='/structures/s397'>Post Office (unbuilt)</SmartLink></li>
          <li><SmartLink to='/structures/s396'>Seaside Avenue Guesthouse for Robert Davis (Unbuilt)</SmartLink></li>
          <li><SmartLink to='/structures/s398'>Seaside Tent (Unbuilt)</SmartLink></li>
          <li><SmartLink to='/structures/s410'>Tupelo St. Work Building (demolished)</SmartLink></li>
          <li><SmartLink to='/structures/s399'>Wedge House (Unbuilt)</SmartLink></li>
          <li><SmartLink to='/structures/s411'>West Side Beachfront Preliminary Plan</SmartLink></li>
          <li><SmartLink to='/structures/s245'>Wilder by the Sea (2444 County Road 30A)</SmartLink></li>
          <h3>Warrior Group</h3>
          <li><SmartLink to='/structures/s349'>Nobella's Retreat (43 Pensacola)</SmartLink></li>
          <h3>Watson, Eric</h3>
          <li><SmartLink to='/structures/s312'>Legacy (141 Smolian Circle)</SmartLink></li>
          <li><SmartLink to='/structures/s221'>Lemonade Stand (2048 County Road 30-A)</SmartLink></li>
          <li><SmartLink to='/structures/s384'>Lucy in the Sky (824 Forest)</SmartLink></li>
          <li><SmartLink to='/structures/s313'>Now and Then (201 Smolian Circle)</SmartLink></li>
          <li><SmartLink to='/structures/s157'>Sabbatical/Dreamboat (14 West Ruskin)</SmartLink></li>
          <li><SmartLink to='/structures/s375'>Sea Chantey (868 Forest)</SmartLink></li>
          <li><SmartLink to='/structures/s190'>Seanome (560 Forest)</SmartLink></li>
          <li><SmartLink to='/structures/s22'>Suite Magnolia I (640 Forest Street)</SmartLink></li>
          <li><SmartLink to='/structures/s32'>The Station (31 Smolian Circle)</SmartLink></li>
          <li><SmartLink to='/structures/s374'>Up on the Roof (860 Forest)</SmartLink></li>
          <h3>Watson, Stroud</h3>
          <li><SmartLink to='/structures/s102'>Equinox (23 East Ruskin)</SmartLink></li>
          <h3>Wernicke, Clay</h3>
          <li><SmartLink to='/structures/s127'>Sunbeam (169 East Ruskin)</SmartLink></li>
          <h3>Wheelwright, Andrew</h3>
          <li><SmartLink to='/structures/s18'>Seduction by Water (305 Ruskin Place)</SmartLink></li>
          <h3>Williamson, Alan</h3>
          <li><SmartLink to='/structures/s103'>Jubilee (499 Forest)</SmartLink></li>
          <li><SmartLink to='/structures/s357'>Summer Solstice (12 Butler)</SmartLink></li>
          <h3>Windsor, Phillip</h3>
          <li><SmartLink to='/structures/s174'>It's Always Five O'Clock (152 East Ruskin)</SmartLink></li>
          <h3>Woodham and Sharpe Architects</h3>
          <li><SmartLink to='/structures/s273'>A Shore Thing (21 Savannah)</SmartLink></li>
        </ul>
      </section>
    </Layout>
  )
}

export default BuildingSeaside
